<template>
  <div>
    <div class="top" style="margin: 20px 0">
      <el-button @click="loanList" type="primary" style="margin-right: 10px"
        >刷新</el-button
      >
      <el-date-picker
        style="margin-right: 20px"
        v-model="time"
        @change="timeUpdate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      <el-input
        type="text"
        style="width: 215px"
        placeholder="请输入手机号"
        v-model="queryList.phone"
        clearable
        @change="
          () => {
            if (!queryList.phone) {
              loanList();
            }
          }
        "
      ></el-input>
      <el-button @click="loanList" type="primary" style="margin-right: 20px"
        >搜索</el-button
      >
      <el-select
        v-model="queryList.score"
        @change="changeLabel"
        placeholder="所有级别"
        clearable
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.value"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <el-table :data="tableData" style="width: 100%" :stripe="true">
      <el-table-column prop="userName" label="姓名"> </el-table-column>
      <el-table-column prop="phone" label="手机号码">
        <template slot-scope="scope">
          <el-button
            v-show="!scope.row.phone"
            @click="showUserPhone(scope.row.id)"
            type="primary"
            >查看</el-button
          >
          <div>{{ scope.row.phone }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="city" label="城市"> </el-table-column>
      <el-table-column prop="pushTime" label="申请时间"> </el-table-column>
      <el-table-column prop="score" label="用户分级"> </el-table-column>
      <el-table-column prop="name" label="跟单情况">
        <template slot-scope="scope"
          ><el-button size="small" @click="look(scope.row)"
            >查看</el-button
          ></template
        >
      </el-table-column>
    </el-table>
    <el-dialog title="跟单情况" :visible.sync="outerVisible">
      <el-dialog
        width="30%"
        title="新增"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-input
          v-model="heel.remarks"
          placeholder="请输入您反馈的内容"
          style="margin-bottom: 15px"
        ></el-input>
        <el-select v-model="heel.score" placeholder="请选择分级" clearable>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="addLook">新增</el-button>
        </div>
      </el-dialog>
      <el-table :data="lookList" border style="width: 100%">
        <el-table-column prop="institutionUserName" label="姓名">
        </el-table-column>
        <el-table-column prop="createTime" label="反馈时间"> </el-table-column>
        <el-table-column prop="remarks" label="反馈内容"> </el-table-column>
        <el-table-column prop="score" label="分级"> </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="primary" @click="innerVisible = true">新增</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogTableVisible2" title="">
      <div class="content" v-if="dialogTableVisible2">
        <div style="font-weight: 700; color: #000">
          <p>姓名：{{ detailsValue.userName }}</p>
          <p>所在城市：{{ detailsValue.city }}</p>
          <p>申请金额：{{ detailsValue.loanMoney }}</p>
          <p>性别：{{ detailsValue.sex }}</p>
          <p>年龄：{{ detailsValue.age }}</p>
          <p>芝麻分：{{ detailsValue.sesameSeed }}</p>
          <p>身份：{{ detailsValue.job }}</p>
          <p>社保：{{ detailsValue.socialSecurity }}</p>
          <p>公积金：{{ detailsValue.accumulationFund }}</p>
          <p>保险：{{ detailsValue.insurance }}</p>
          <p>车产：{{ detailsValue.carStatus }}</p>
          <p>房产：{{ detailsValue.houseStatus }}</p>
          <p>本科：{{ detailsValue.study }}</p>
          <p>逾期：{{ detailsValue.overdue }}</p>
          <p>信用卡：{{ detailsValue.creditCard }}</p>
          <p>微粒贷：{{ detailsValue.smallLoan }}</p>
        </div>
        <div style="font-weight: 700; color: #000; text-align: center">
          <p>推送时间：{{ detailsValue.updateTime }}</p>
        </div>
        <p style="width: 100%; text-align: center">
          <el-button @click="dialogTableVisible2 = false" type="primary"
            >确认</el-button
          >
        </p>
      </div>
    </el-dialog>

    <pagination
      :total="total"
      :page.sync="queryList.pageIdx"
      :limit.sync="queryList.pageSize"
      @pagination="pagination"
    />
  </div>
</template>

<script>
function jialing(num) {
  if (num >= 10) {
    return num;
  } else {
    return "0" + num;
  }
}
var day1 = new Date();
day1.setTime(day1.getTime());
var s1 =
  day1.getFullYear() +
  "-" +
  jialing(day1.getMonth() + 1) +
  "-" +
  jialing(day1.getDate());
var day3 = new Date();
day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
var s3 =
  day3.getFullYear() +
  "-" +
  jialing(day3.getMonth() + 1) +
  "-" +
  jialing(day3.getDate());
import { list_inst_user, add_user, list_user, find_phone } from "../../api/user";
import { find_by } from "../../api/OrderManagement";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      /*       detailsValue: [
              {
                userName: 'xxx'
              }
            ], */
      time: [s1, s3],
      queryList: {
        startTime: "",
        endTime: "",
        pageIdx: 1,
        pageSize: 10,
        token: sessionStorage.getItem("token"),
        score: "",
        phone: ''
      },
      options: [
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
        {
          value: 5,
        },
      ],
      tableData: [],
      total: 0,
      dialogTableVisible2: false,
      outerVisible: false,
      innerVisible: false,
      lookList: [],
      heel: {
        remarks: "",
        score: "",
        token: sessionStorage.getItem("token"),
        userId: 0,
      },
      datac: {}
    };
  },
  mounted() {
    this.queryList.startTime = s1;
    this.queryList.endTime = s3;
    this.loanList();
  },
  methods: {
    showUserPhone(id) {
      find_phone(id).then((res) => {
        if (res.code === 200) {
          this.tableData.forEach((item) => {
            if (item.phone) {
              delete item.phone;
              return false;
            }
          })
          this.tableData.forEach((item) => {
            if (item.id === id) {
              item.phone = res.data;
              this.tableData = JSON.parse(JSON.stringify(this.tableData))
              return false;
            }
          })
        }
      })
    },
    loanList() {
      list_inst_user(this.queryList).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    timeUpdate(val) {
      if (val != null) {
        this.queryList.startTime = val[0];
        this.queryList.endTime = val[1];
      } else {
        this.queryList.startTime = "";
        this.queryList.endTime = "";
      }
      this.queryList.pageIdx = 1;
      this.loanList();
    },
    handleSelectionChange() { },
    look(data) {
      this.datac = data;
      this.outerVisible = true;
      list_user({
        userId: data.id,
        pageIdx: 1,
        pageSize: 10
      }).then((res) => {
        this.lookList = res.data.records;
      });
    },
    details(id) {
      find_by(id).then((res) => {
        if (res.code == 200) {
          this.detailsValue = res.data;
          this.dialogTableVisible2 = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addLook() {
      var that = this;
      this.heel.userId = this.datac.id;
      add_user(this.heel).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "新增成功",
            type: "success"
          });
          list_user({
            userId: that.datac.id,
            pageIdx: 1,
            pageSize: 10,
            token: sessionStorage.getItem("token"),
          }).then((ress) => {
            that.lookList = ress.data.records;
          });
          this.innerVisible = false;
          this.heel.remarks = "";
          this.heel.score = "";
          this.loanList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //分页
    pagination(params) {
      if (params.page) {
        this.queryList.pageIdx = params.page;
      } else {
        this.queryList.pageIdx = 1;
        this.queryList.pageSize = params.limit;
      }
      this.loanList();
    },
    //修改级别
    changeLabel() {
      this.queryList.pageIdx = 1;
      this.loanList();
    }
  },
  filters: {
    addStar(newValue) {
      let str = newValue[0];
      for (let i = 1; i < newValue.length; i++) {
        str += '*';
      }
      return str;
    }
  }
};
</script>

<style scoped>
::v-deep .el-date-editor .el-range-separator {
  width: 9%;
}
::v-deep .el-date-editor--daterange.el-input__inner {
  width: 250px !important;
}
::v-deep .el-select {
  width: 150px;
  margin-right: 10px;
}
::v-deep .el-radio {
  margin-right: 10px;
}
::v-deep thead th {
  color: #000;
  background: rgb(250, 250, 250);
}
.content {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

.content div {
  float: left;
  margin-left: 100px;
}

.content div p {
  margin-bottom: 10px;
}

::v-deep .el-pagination {
  text-align: center;
}
</style>
