import request from './request.js';
//分单管理列表
export function list_inst_user(data) {
    return request({
        url: '/user/inst/list-inst-user',
        method: 'get',
        params: data
    })
}
//新增跟单记录
export function add_user(data) {
    return request({
        url: '/user/follow/add-user-follow',
        method: 'post',
        data
    })
}
//查看跟单记录
export function list_user(data) {
    return request({
        url: '/user/follow/list-user-follow',
        method: 'get',
        params: data
    })
}

//查找手机号
export function find_phone(id) {
    return request({
        url: '/user/inst/find-user-phone/' + id,
        method: 'get'
    })
}